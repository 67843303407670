// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';
@import '~flatpickr/dist/flatpickr.css';
// @import 'core/vendors/plugins/_flatpickr.scss';

#root {
  display: contents;
}

.frozen-column {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: #fff !important; /* Ensure the frozen columns have a white background */
  transition: box-shadow 0.3s ease-in-out;
}

.frozen-column-shadow {
  box-shadow: 10px 10px 5px lightblue !important;
}
